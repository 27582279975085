export const bijuterii = [
  {
    id: 1,
    numePoza: "./bijuAivalf/B1.jpeg",
    tumbPoza: "./bijuAivalf/tumbB1.jpeg",
    descr: "Colier metalic",
    tip: "",
    culori: "",
    dimens: "accesoriu perla"
  },
  {
    id: 2,
    numePoza: "./bijuAivalf/B2.jpeg",
    tumbPoza: "./bijuAivalf/tumbB2.jpeg",
    descr: "Bratara impletita",
    tip: "",
    culori: "",
    dimens: "imitatie de piele"
  },
  {
    id: 3,
    numePoza: "./bijuAivalf/B3.jpeg",
    tumbPoza: "./bijuAivalf/tumbB3.jpeg",
    descr: "",
    tip: "",
    culori: "",
    dimens: "margele de sticla"
  },
  {
    id: 4,
    numePoza: "./bijuAivalf/B4.jpeg",
    tumbPoza: "./bijuAivalf/tumbB4.jpeg",
    descr: "Bratara margele",
    tip: "",
    culori: "",
    dimens: "accesoriu metalic"
  },
  {
    id: 5,
    numePoza: "./bijuAivalf/B5.jpeg",
    tumbPoza: "./bijuAivalf/tumbB5.jpeg",
    descr: "Bratara margele",
    tip: "",
    culori: "",
    dimens: "accesoriu metalic"
  },
  {
    id: 6,
    numePoza: "./bijuAivalf/B6.jpeg",
    tumbPoza: "./bijuAivalf/tumbB6.jpeg",
    descr: "Bratara margele",
    tip: "",
    culori: "",
    dimens: "detalii metalice"
  },
  {
    id: 7,
    numePoza: "./bijuAivalf/B7.jpeg",
    tumbPoza: "./bijuAivalf/tumbB7.jpeg",
    descr: "Cercei metalici",
    tip: "",
    culori: "",
    dimens: "accesoriu perlat"
  },
  {
    id: 8,
    numePoza: "./bijuAivalf/B8.jpeg",
    tumbPoza: "./bijuAivalf/tumbB8.jpeg",
    descr: "Cercei metalici",
    tip: "",
    culori: "",
    dimens: "mesaj"
  },
  {
    id: 9,
    numePoza: "./bijuAivalf/B9.jpeg",
    tumbPoza: "./bijuAivalf/tumbB9.jpeg",
    descr: "Pandantiv fazele lunii",
    tip: "",
    culori: "",
    dimens: "modelat manual"
  },
  {
    id: 10,
    numePoza: "./bijuAivalf/B10.jpeg",
    tumbPoza: "./bijuAivalf/tumbB10.jpeg",
    descr: "Brosa origami",
    tip: "",
    culori: "",
    dimens: "modelata manual"
  }
];
