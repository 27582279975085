export const schitele = [
  {
    id: 1,
    numePoza: "./schiteAivalf/S1.jpeg",
    tumbPoza: "./schiteAivalf/tumbS1.jpeg",
    descr: "",
    tip: "",
    culori: "",
    dimens: "155 x 150 mm"
  },
  {
    id: 2,
    numePoza: "./schiteAivalf/S2.jpeg",
    tumbPoza: "./schiteAivalf/tumbS2.jpeg",
    descr: "",
    tip: "",
    culori: "",
    dimens: "210 x 150 mm"
  },
  {
    id: 3,
    numePoza: "./schiteAivalf/S3.jpeg",
    tumbPoza: "./schiteAivalf/tumbS3.jpeg",
    descr: "",
    tip: "",
    culori: "",
    dimens: "115 x 150 mm"
  },
  {
    id: 4,
    numePoza: "./schiteAivalf/S4.jpeg",
    tumbPoza: "./schiteAivalf/tumbS4.jpeg",
    descr: "",
    tip: "",
    culori: "",
    dimens: "165 x 165 mm"
  },
  {
    id: 5,
    numePoza: "./schiteAivalf/S5.jpeg",
    tumbPoza: "./schiteAivalf/tumbS5.jpeg",
    descr: "",
    tip: "",
    culori: "",
    dimens: "165 x 165 mm"
  },
  {
    id: 6,
    numePoza: "./schiteAivalf/S6.jpeg",
    tumbPoza: "./schiteAivalf/tumbS6.jpeg",
    descr: "",
    tip: "",
    culori: "",
    dimens: "210 x 150 mm"
  },
  {
    id: 7,
    numePoza: "./schiteAivalf/S7.jpeg",
    tumbPoza: "./schiteAivalf/tumbS7.jpeg",
    descr: "",
    tip: "",
    culori: "",
    dimens: "210 x 150 mm"
  },
  {
    id: 8,
    numePoza: "./schiteAivalf/S8.jpeg",
    tumbPoza: "./schiteAivalf/tumbS8.jpeg",
    descr: "",
    tip: "",
    culori: "",
    dimens: "115 x 150 mm"
  }
];
