import React from 'react';
import './Titles.css';
import './backtit.png';

const Titles = () => {
  return (
    <div className='flex flex-column tc ml3 pa1'>
      <div className='titlu center b f1'> Aivalf Design </div>
      <div className='titlu sectitle b f3 moon-gray'> Build to design ! </div>
    </div>
  )
}

export default Titles;
