export const decoratiuni = [
  {
    id: 1,
    numePoza: "./decoAivalf/D1.jpeg",
    tumbPoza: "./decoAivalf/tumbD1.jpeg",
    descr: "Decoratiune planta",
    tip: "",
    culori: "",
    dimens: "modelata manual"
  },
  {
    id: 2,
    numePoza: "./decoAivalf/D2.jpeg",
    tumbPoza: "./decoAivalf/tumbD2.jpeg",
    descr: "Decoratiune glob",
    tip: "",
    culori: "",
    dimens: "trandafir uscat"
  },
  {
    id: 3,
    numePoza: "./decoAivalf/D3.jpeg",
    tumbPoza: "./decoAivalf/tumbD3.jpeg",
    descr: "Suport lumanare",
    tip: "",
    culori: "",
    dimens: "modelat manual"
  },
  {
    id: 4,
    numePoza: "./decoAivalf/D4.jpeg",
    tumbPoza: "./decoAivalf/tumbD4.jpeg",
    descr: "Lampa de veghe",
    tip: "",
    culori: "",
    dimens: "model oras"
  }
];
